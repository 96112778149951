<template>
  <div
    v-if="!isLoading"
    class="recipeModal"
  >
    <div class="recipeModal__info">
      <video-player
        v-if="video.length"
        class="recipeModal__video"
        v-bind:src="video"
        v-bind:placeholder="`${currentRecipe.image}?w=380&q=80`"
        v-bind:title="currentRecipe.name"
        v-bind:track-program="currentRecipe.theme"
        v-bind:track-title="currentRecipe.name"
        v-bind:track-id="currentRecipe.id"
        track-goal="Recipe"
      />

      <img
        v-if="!video.length"
        v-bind:src="`${currentRecipe.image}?w=380&q=80`"
        v-bind:srcset="`${currentRecipe.image}?w=380&q=80, ${currentRecipe.image}?w=760&q=80 2x`"
        class="recipeModal__image"
      />

      <div class="recipeModal__title">
        {{ currentRecipe.name }}
      </div>

      <p
        v-html-safe="currentRecipe.description"
        class="recipeModal__body"
      />

      <div class="recipeModal__detailsContainer">
        <ul class="recipeModal__details">
          <li>
            <font-awesome-icon
              v-bind:icon="['fal', 'clock']"
              class="recipeModal__detailIcon"
            />

            {{ $t('recipes.preparationTimeMinutes', { time: currentRecipe.prep_time }) }}
          </li>

          <li v-if="currentRecipe.servings">
            <font-awesome-icon
              icon="user-group"
              class="recipeModal__detailIcon"
            />

            {{ $t('recipeDetail.servingsLabel', { numServings: currentRecipe.servings }) }}
          </li>

          <li v-if="currentRecipe.recipe_type">
            <font-awesome-icon
              icon="tag"
              class="recipeModal__detailIcon"
            />

            {{ currentRecipe.recipe_type }}
          </li>

          <li v-if="currentRecipe.course">
            <font-awesome-icon
              icon="utensils"
              class="recipeModal__detailIcon"
            />

            {{ currentRecipe.course }}
          </li>
        </ul>

        <div
          v-if="currentRecipe.energy || currentRecipe.proteins || currentRecipe.carbohydrates || currentRecipe.fats"
          class="recipeModal__nutritions"
        >
          <div class="recipeModal__nutritionsTitle">
            {{ $t('recipeDetail.nutritionsTitle') }}
          </div>

          <ul>
            <li v-if="currentRecipe.energy">
              {{ currentRecipe.energy }}
            </li>

            <li v-if="currentRecipe.proteins">
              {{ currentRecipe.proteins }}
            </li>

            <li v-if="currentRecipe.carbohydrates">
              {{ currentRecipe.carbohydrates }}
            </li>

            <li v-if="currentRecipe.fats">
              {{ currentRecipe.fats }}
            </li>
          </ul>
        </div>
      </div>

      <div class="recipeModal__buttons">
        <base-button
          modifiers="white small"
          class="recipeModal__button"
          v-on:click="toggleFavorite(currentRecipe.id)"
        >
          {{ $t('recipeDetail.favoriteButtonLabel') }}

          <font-awesome-icon
            v-bind:icon="[isFavorite ? 'fas' : 'fal', 'heart']"
            class="recipeModal__buttonIcon"
          />
        </base-button>

        <base-button
          modifiers="white small"
          class="recipeModal__button"
          v-on:click="print()"
        >
          {{ $t('recipeDetail.printButtonLabel') }}

          <font-awesome-icon
            icon="print"
            class="recipeModal__buttonIcon"
          />
        </base-button>
      </div>

      <div class="recipeModal__shareTitle">
        {{ $t('recipeDetail.shareTitle') }}
      </div>

      <div
        class="recipeModal__shareOption recipeModal__shareOption--link"
        v-on:click="onCopyLinkClick"
      >
        <font-awesome-icon
          icon="link"
          class="recipeModal__shareOptionIcon"
        />

        <div
          v-if="showLinkCopied"
          class="recipeModal__shareOptionNotification"
        >
          {{ $t('recipeDetail.shareLinkCopied') }}
        </div>
      </div>
    </div>

    <div class="recipeModal__content">
      <div class="recipeModal__ingredientsTitle">
        {{ $t('recipeDetail.ingredientsTitle') }}
      </div>

      <p
        v-html-safe="currentRecipe.ingredients"
        class="recipeModal__ingredients"
      />

      <div class="recipeModal__preparationsTitle">
        {{ $t('recipeDetail.preparationsTitle') }}
      </div>

      <p
        v-html-safe="currentRecipe.preparations"
        class="recipeModal__preparations"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import BaseButton from '@/components/BaseButton';
import VideoPlayer from '@/components/VideoPlayer';

export default {
  components: {
    BaseButton,
    VideoPlayer,
  },

  props: {
    recipeId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      isLoading: true,
      showLinkCopied: false,
    };
  },

  computed: {
    ...mapState('recipe', ['currentRecipe', 'favorites']),

    isFavorite() {
      return this.favorites[this.recipeId];
    },

    video() {
      const files = [];

      if (this.currentRecipe.files.m3u8) {
        files.push({
          file: this.currentRecipe.files.m3u8,
          type: 'hls',
        });
      }

      if (this.currentRecipe.files.mp4) {
        files.push({
          file: this.currentRecipe.files.mp4,
          type: 'mp4',
        });
      }

      return files;
    },
  },

  async mounted() {
    await this.loadRecipe(this.recipeId);

    this.isLoading = false;
    this.$store.commit('track/recipe', this.currentRecipe);
  },

  unmounted() {
    this.$store.commit('recipe/unsetRecipe');
  },

  methods: {
    ...mapActions({
      loadRecipe: 'recipe/getRecipe',
      toggleFavorite: 'recipe/toggleFavorite',
    }),

    onCopyLinkClick() {
      const path = this.$router.resolve({
        name: 'recipeSingle',
        params: {
          recipeId: this.recipeId,
        },
      });

      navigator.clipboard.writeText(`${window.location.origin}${path.href}`)
        .then(this.onCopyLinkSuccess);
    },

    onCopyLinkSuccess() {
      this.showLinkCopied = true;

      setTimeout(() => {
        this.showLinkCopied = false;
      }, 3000);
    },

    print() {
      window.print();
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/lib";

.recipeModal__body,
.recipeModal__ingredients,
.recipeModal__preparations {
  &,
  p,
  ul,
  li {
    @include small;

    @include desktop {
      @include paragraph;
    }
  }
}
</style>

<style lang="scss" scoped>
@import "@/scss/lib";

.recipeModal {
  @include desktop {
    display: flex;
  }
}

.recipeModal__info,
.recipeModal__content {
  padding: rem(30px) rem(16px);

  @include desktop {
    flex: 0 1 50%;
  }
}

.recipeModal__info {
  background: $color-beige url("~@/assets/recipe/background-pattern.svg") repeat 0 0;
}

.recipeModal__video,
.recipeModal__image {
  margin: rem(-30px) rem(-16px) rem(30px) rem(-16px);
  width: calc(100% + 32px);
  border-radius: 0;
}

.recipeModal__image {
  display: block;
}

.recipeModal__title {
  @include heading-3;
  margin: 0 0 rem(20px) 0;

  @include desktop {
    @include heading-2;
  }
}

.recipeModal__body {
  margin: 0 0 rem(20px) 0;
}

.recipeModal__detailsContainer {
  display: flex;
  margin: 0 0 rem(30px) 0;

  @media(min-width: 768px) {
    display: block;
  }

  @media(min-width: 1024px) {
    display: flex;
  }
}

.recipeModal__details {
  @include small;
  flex: 0 0 50%;
  margin: 0;
  padding: 0;
  list-style: none;

  @include desktop {
    @include paragraph;
  }

  @media(min-width: 768px) {
    margin: 0 0 rem(20px) 0;
  }

  @media(min-width: 1024px) {
    margin: 0;
  }
}

.recipeModal__detailIcon {
  margin: 0 rem(8px) 0 0;
  width: 18px;
  font-size: 14px;
}

.recipeModal__nutritions {
  flex: 0 0 50%;
}

.recipeModal__nutritionsTitle {
  @include note;
  text-transform: uppercase;
}

.recipeModal__button {
  &:first-child {
    margin: 0 rem(10px) rem(10px) 0;
  }
}

.recipeModal__buttonIcon {
  margin: 0 0 0 rem(16px);
}

.recipeModal__shareTitle {
  @include tiny--bold;
  margin: rem(20px) 0 rem(5px) 0;
  text-transform: uppercase;
}

.recipeModal__shareOption {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 rem(5px);
  width: 48px;
  height: 48px;
  font-size: 20px;
  border-radius: 50%;
  background: $color-grey;
  cursor: pointer;
}

.recipeModal__shareOptionIcon {
  color: $color-white;
}

.recipeModal__shareOptionNotification {
  @include note;
  position: absolute;
  top: calc(50% - 12px);
  left: 58px;
  padding: rem(3px) rem(16px);
  border-radius: 6px;
  background: $color-grey;
  color: $color-white;
  white-space: nowrap;
}

.recipeModal__ingredientsTitle,
.recipeModal__preparationsTitle {
  @include paragraph--bold;
  margin: 0 0 rem(16px) 0;

  @include desktop {
    @include lead--bold;
  }
}

.recipeModal__ingredients {
  margin: 0 0 rem(16px) 0;
}

@media print {
  .recipeModal {
    display: block;
  }

  .recipeModal__video,
  .recipeModal__image,
  .recipeModal__buttons,
  .recipeModal__shareTitle {
    display: none;
  }

  .recipeModal__detailsContainer {
    margin: 0;
  }

  .recipeModal__info {
    padding-bottom: 0;
    background: none;
  }
}
</style>
